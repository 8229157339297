.switch-custom {
  transform-origin: bottom left;
  transform: scale(1.7);
  margin: 14px 0px 0px 0px;
}

.btn-group-custom {
  flex-wrap: wrap;
}

.grab-grabbing {
  cursor: grab;
}

.grab-grabbing:active {
  cursor: grabbing;
}

.pointer {
  cursor: pointer;
}

.fixed-table-parent {
  overflow: auto;
  width: 100%;
  max-height: 500px;
}
.fixed-table thead tr th {
  position: sticky;
  top: 0;
}
.fixed-table tr th:first-child {
  position: sticky;
  left: 0;
}
.fixed-table thead tr th:first-child {
  z-index: 1;
  background: #ffffff;
}

.footer {
  height: 50px;
  background-color: #E9ECEF;
}

.footer p {
  font-size: 12px;
}

$theme-colors: (
  'primary': #6F42C1,
  'secondary': #6C757D,
  'warning': #FFC107
);

$form-check-input-checked-bg-color: #6F42C1;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';